<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="1200px">
        <v-card>
          <v-card-title>
            <span class="headline">Nuevo Documento de Compra</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" :lazy-validation="false">
                <v-row>
                  <v-col cols="12" md="3">
                    <v-select
                      label="Tipo de Documento"
                      :items="typeDoc"
                      item-text="name"
                      item-value="id"
                      v-model="documentType"
                      :rules="onlyRequired"
                      ref="ref_type_doc"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Serie"
                      v-model="body.serie"
                      :rules="onlyRequired"
                      ref="ref_serie"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Número"
                      v-model="body.invoice_number"
                      :rules="onlyRequired"
                      ref="ref_number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <date-field
                      :reset="reset"
                      label="Fecha"
                      v-model="body.invoice_date"
                      :rules="onlyRequired"
                      ref="ref_date"
                    ></date-field>
                  </v-col>
                  <v-row v-if="!disabledP && credit != 'CJCH'">
                    <v-col cols="12" md="4" v-show="!disabledP">
                      <company-field
                        v-model="companyId"
                        @value="(val) => (companyId = val)"
                        :reset="reset"
                      ></company-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <provider-field
                        v-model="providerId"
                        :disabled="disabledP"
                        :reset="reset"
                        required
                      ></provider-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-select
                        label="Tipo"
                        :items="tipos"
                        item-text="name"
                        item-value="value"
                        v-model="body.type"
                        :rules="onlyRequired"
                        ref="ref_type"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row v-else>
                    <v-col cols="12" md="10">
                      <provider-field
                        v-model="providerId"
                        :disabled="disabledP && credit != 'CJCH'"
                        required
                      ></provider-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-select
                        label="Tipo"
                        :items="tipos"
                        item-text="name"
                        item-value="value"
                        v-model="body.type"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-row>

                <v-row v-if="body.document_type_id == 4 && invoiceDialog" align="center">
                  <v-col cols="12" md="10">
                    <invoice-field
                      :dialog="invoiceDialog"
                      :providerId="invoiceProvider"
                      :businessId="invoiceCompany"
                      :type="'VOUCHER,INVOICE'"
                      :reload="load"
                      :reset="resetSlc"
                      :exclude="excludeInvoices"
                      v-model="invoice"
                    ></invoice-field>
                  </v-col>
                  <v-col cols="12" md="1">
                    <v-btn class="mx-2" fab dark small color="primary" @click="reload()">
                      <v-icon dark>mdi-refresh</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-data-table
                      :headers="headersInvoice"
                      v-model="invoices"
                      :items="invoices"
                      item-key="id"
                      hide-default-footer
                      required
                    >
                      <template v-slot:[`item.payment`]="{ item }">
                        <v-text-field
                          type="number"
                          step="any"
                          v-model="item.payment"
                        ></v-text-field>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          small
                          color="warning"
                          @click="remove(item.id)"
                        >
                          <v-icon dark> mdi-minus </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-row class="text-center body-2">
                    <v-col cols="12" md="6">
                      <span align="right"><b>Total</b></span>
                    </v-col>
                    <v-col cols="12" md="6" class="text-center">
                      <span
                        ><b>{{ body.amount }}</b></span
                      >
                    </v-col>
                  </v-row>
                </v-row>

                <v-row v-if="body.invoice_type == 'VOUCHER'">
                  <v-col cols="12" md="12">
                    <v-data-table
                      v-model="selected"
                      :headers="headers"
                      :items="orders"
                      :search="invoiceSearch"
                      item-key="id"
                      show-select
                      height="200"
                      class="elevation-1"
                      hide-default-footer
                      disable-pagination
                      required
                    >
                      <template v-slot:top v-if="orders.length > 0">
                        <v-text-field
                          v-model="invoiceSearch"
                          append-icon="mdi-magnify"
                          label="Buscar"
                          clearable
                          class="mx-4"
                        ></v-text-field>
                      </template>
                      <template
                        v-if="body.invoice_type == 'VOUCHER'"
                        v-slot:[`item.description`]="{ item }"
                      >
                        {{ item.observations }}
                      </template>
                    </v-data-table>
                    <v-row>
                      <v-col cols="12" md="9">
                        <p align="right"><b>Total</b></p>
                      </v-col>
                      <v-col cols="12" md="2">
                        <span>{{ body.total_order }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="body.document_type_id != 4">
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Total"
                      v-model="body.amount"
                      :rules="onlyRequired"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Retención ISR"
                      v-model="body.isr"
                      :rules="isrRule"
                      ref="ref_isr"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Retención IVA"
                      v-model="body.iva"
                      :rules="ivaRule"
                      ref="ref_iva"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Descuento"
                      v-model="body.discount"
                      :rules="discRule"
                      ref="ref_disc"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="5">
                    <v-text-field
                      label="Descripción de la Compra"
                      v-model="body.description"
                      counter="100"
                      :rules="onlyRequired"
                      ref="ref_desc"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <classification-field
                      v-model="classificationId"
                      :reset="reset"
                      :rules="onlyRequired"
                    ></classification-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      label="Periodo Contable"
                      :items="periods"
                      :item-text="(item) => `${item.year} - ${item.month}`"
                      item-value="id"
                      v-model="body.working_period_id"
                      ref="ref_period"
                      :rules="onlyRequired"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <!-- detalle fauca -->
                <v-container
                  v-if="
                    body.document_type_id == 10 ||
                    body.document_type_id == 9 ||
                    body.document_type_id == 8
                  "
                >
                  <v-row v-for="(dt, key) in detailFauca" :key="key">
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="dt.text"
                        ref="ref_exempt"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Monto"
                        v-model="dt.value"
                        ref="ref_exempt"
                        :rules="dt.id == 0 ? amountRule : emptyRule"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <v-row>
                  <v-col md="2">
                    <div justify="end">
                      <v-switch
                        label="Agregar Exento"
                        v-model="thereIsExempt"
                      ></v-switch>
                    </div>
                  </v-col>
                  <v-col cols="12" md="3" v-show="thereIsExempt">
                    <v-select
                      label="Tipo de exento"
                      :items="typeExempt"
                      item-text="name"
                      item-value="id"
                      v-model="body.type_exempt_id"
                      ref="ref_type_exempt"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3" v-show="thereIsExempt">
                    <v-text-field
                      label="Exento"
                      v-model="body.exempt"
                      ref="ref_exempt"
                      :rules="exemptRule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close()"> Cerrar </v-btn>
            <v-btn color="blue darken-1" text @click="save()"> Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import request from "@/services/requests";
import rules from "@/utils/form.rules.js";
export default {
  components: {
    DateField: () => import("@/components/fields/DateField.vue"),
    ProviderField: () => import("@/components/fields/ProviderField"),
    ClassificationField: () => import("@/components/accounting/ClassificationField"),
    CompanyField: () => import("@/components/fields/CompanyField"),
    InvoiceField: () => import("@/components/fields/InvoiceField"),
  },
  props: {
    dialog: { type: Boolean, required: true },
    provider: { type: Number, requried: false },
    business: { type: Number, requried: false },
    credit: { type: String, requried: false },
    disabledP: { type: Boolean, required: false, default: true },
  },
  data: () => ({
    headersInvoice: [
      { text: "Factura", value: "code" },
      { text: "Descripción", value: "description" },
      { text: "Saldo", value: "balance" },
      { text: "Descuento", value: "payment" },
      { text: "", value: "actions" },
    ],
    detailFauca: [
      // No nulo id 0, suma de estos debe sumar total factura
      { text: "COMPRAS DE IMPORTACIÓN", value: "0", id: 0 },
      { text: "COMPRAS DE IMPORTACIÓN EXENTO", value: "0", id: 3 },
      { text: "GASTOS DE IMPORTACIÓN AFECTO", value: "0", id: 1 },
      { text: "GASTOS DE IMPORTACIÓN EXENTO", value: "0", id: 2 },
    ],
    invoiceDialog: false,
    invoiceCompany: undefined,
    invoiceProvider: undefined,
    invoice: {},
    invoices: [],
    excludeInvoices: [],
    load: false,
    periods: [],
    reset: false,
    resetSlc: false,
    thereIsExempt: false,
    companyId: undefined,
    invoiceSearch: "",
    isCredit: false,
    type: undefined,
    orders: [],
    businessId: 0,
    messageError: "",
    valid: false,
    providerId: 0,
    classificationId: {},
    invoiceType: [
      { text: "Factura por Orden contado", value: "ORDCONT" },
      { text: "Factura por Orden Crédito", value: "ORDCRE" },
      { text: "Factura por Voucher", value: "VOUCHER" },
      { text: "Factura sin Orden", value: "INVOICE" },
      { text: "Factura sin Documento", value: "N/A" },
    ],
    tipos: [
      { name: "Bien", value: "b" },
      { name: "Servicio", value: "s" },
    ],
    typeDoc: [],
    typeExempt: [],
    documentType: "",
    body: {
      serie: "",
      invoice_number: "",
      company_id: "",
      invoice_date: "",
      description: "",
      provider_id: "",
      amount: "",
      balance: "",
      isr: "0",
      iva: "0",
      document_type_id: "",
      type_exempt_id: undefined,
      exempt: 0,
      type: "",
      discount: "0",
      little_taxpayer: false,
      invoice_type: "",
      orders: [],
      vouchers: [],
      invoices: [],
      working_period_id: undefined,
      classification_id: 0,
      detail: [],
    },
    headers: [
      { text: "No. orden", value: "code" },
      { text: "Descripción", value: "description" },
      { text: "Total", value: "amount" },
    ],
    selected: [],
    //rules
    onlyRequired: [rules.required],
    isrRule: [],
    ivaRule: [],
    discRule: [],
    exemptRule: [],
    amountRule: [rules.required, rules.minValue(0.01)],
    emptyRule: [rules.minValue(0)],
  }),
  methods: {
    isNum(val) {
      let num = 0;
      if (isNaN(val)) {
        num = 0;
      } else {
        num = Number(val).toFixed(2);
      }
      return num;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.body.balance = this.body.amount;
        // fauca, duca y fyduca
        if (
          (this.body.document_type_id == 10 ||
            this.body.document_type_id == 9 ||
            this.body.document_type_id == 8) &&
          this.detailFauca.length > 0
        ) {
          this.body.detail = [];
          let total = 0;
          this.detailFauca.forEach((el) => {
            this.body.detail.push({
              description: el.text,
              amount: Number(el.value).toFixed(2),
            });
            total = Number(Number(el.value) + Number(total)).toFixed(2);
          });
          if (total != Number(this.body.amount).toFixed(2)) {
            this.$toasted.global.error({
              message:
                "El monto de la factura debe coincidir con el total de los gastos y compras de importación",
            });
            return false;
          }
        }
        if (this.selected.length > 0) {
          if (
            Number(this.body.amount) !=
            Number(this.body.total_order) - Number(this.isNum(this.body.discount))
          ) {
            this.$toasted.global.error({
              message:
                "El monto de la factura debe coincidir con el total de los documentos",
            });
            return false;
          }
        }
        if (this.body.invoice_type == "VOUCHER") {
          if (this.selected.length > 0) {
            this.selected.forEach((el) => {
              this.body.vouchers.push({
                id: el.id,
              });
            });
          }
        }
        // NCRE
        if (this.body.document_type_id == 4) {
          this.body.invoices = [];
          if (this.invoices.length > 0) {
            this.invoices.forEach((el) => {
              this.body.invoices.push({ id: el.id, amount: el.payment });
            });
          } else {
            return this.$toasted.global.info({
              message: "Nota de Crédito necesita aplicarse al menos a un documento",
            });
          }
        }

        request.post("/api/invoice/", this.body).then((res) => {
          if (res.status == 201) {
            this.$toasted.global.info({
              message: "Registro Creado",
            });
            this.$emit("saved");
            this.close();
            this.getOrders();
          } else if (res.status == 400) {
            if ("non_field_errors" in res.data) {
              if (res.data.non_field_errors.length > 0) {
                res.data.non_field_errors.forEach((el) => {
                  this.messageError = el.replace("invoice_number", "número de factura");
                  this.messageError = this.messageError.replace(
                    "provider_id",
                    "proveedor"
                  );
                });
              } else {
                console.log(res.data);
              }
            } else if ("detail" in res.data) {
              this.messageError = res.data.detail;
            } else if ("description" in res.data) {
              this.messageError = res.data.description;
            }
            this.$toasted.global.info({
              message: this.messageError,
            });
          }
        });
      }
    },
    close() {
      this.reset = this.reset ? false : true;
      this.$emit("close");
      this.ivaRule = [];
      this.isrRule = [];
      this.discRule = [];
      this.body.serie = "";
      this.body.invoice_number = "";
      this.body.invoice_date = "";
      this.body.description = "";
      this.body.orders = [];
      this.body.invoices = [];
      this.body.amount = "";
      this.thereIsExempt = false;
      this.body.type_exempt_id = undefined;
      this.body.total_order = 0;
      this.exempt = "0";
      this.body.isr = "0";
      this.body.iva = "0";
      this.body.discount = "0";
      this.documentType = "";
      this.body.document_type_id = "";
      this.body.type = "";
      this.body.little_taxpayer = false;
      this.body.detail = [];
      this.selected = [];
      this.working_period_id = undefined;
      this.body.classification_id = {};
      this.detailFauca = [
        { text: "COMPRAS DE IMPORTACIÓN", value: "0", id: 0 },
        { text: "COMPRAS DE IMPORTACIÓN EXENTO", value: "0", id: 3 },
        { text: "GASTOS DE IMPORTACIÓN AFECTO", value: "0", id: 1 },
        { text: "GASTOS DE IMPORTACIÓN EXENTO", value: "0", id: 2 },
      ];
      this.$refs.ref_serie.reset();
      this.$refs.ref_type_doc.reset();
      this.$refs.ref_type.reset();
      this.$refs.ref_number.reset();
      this.$refs.ref_desc.reset();
      if (this.$refs.ref_amount != undefined) {
        this.$refs.ref_amount.reset();
      }
      this.$refs.ref_period.reset();
      this.invoice = {};
      this.invoices = [];

      // }
    },
    getOrders() {
      if (
        this.body.company_id != "" &&
        this.body.company_id != undefined &&
        this.body.provider_id != "" &&
        this.body.provider_id != undefined
      ) {
        if (this.body.invoice_type == "ORDCRE") {
          request
            .get(
              "api/sale-orders/orders/?company=" +
                this.body.company_id +
                "&provider_id=" +
                this.body.provider_id +
                "&credit=true"
            )
            .then((res) => {
              if (res.status == 200) {
                this.orders = res.data;
              }
            });
        } else if (this.body.invoice_type == "VOUCHER") {
          request
            .get(
              "api/vouchers/?company=" +
                this.body.company_id +
                "&provider_id=" +
                this.body.provider_id
            )
            .then((res) => {
              if (res.status == 200) {
                this.orders = res.data;
              }
            });
        }
      }
    },
    getTributaryDocumentType() {
      request.get("api/accounting/document-type/").then((res) => {
        if (res.status == 200) {
          this.typeDoc = res.data.results;
        }
      });
    },
    getTypeExempt() {
      request.get("api/accounting/type-exempt/").then((res) => {
        if (res.status == 200) {
          this.typeExempt = res.data.results;
        }
      });
    },
    getWorkingPeriod() {
      request.get("api/accounting/working-period/").then((res) => {
        if (res.status == 200) {
          this.periods = res.data.results;
        } else {
          console.error(res);
        }
      });
    },
    reload() {
      this.load = this.load ? false : true;
    },
    resetVal() {
      this.resetSlc = this.resetSlc ? false : true;
    },
    ncre() {
      if (this.body.company_id != undefined) {
        this.invoiceCompany = this.body.company_id;
      }
      if (this.body.provider_id != undefined) {
        this.invoiceProvider = this.body.provider_id;
      }
      if (this.invoiceCompany != undefined && this.invoiceProvider != undefined) {
        this.invoiceDialog = true;
      }
    },
    remove(id) {
      const index = this.invoices.findIndex((invoice) => invoice.id === id);
      this.invoices.splice(index, 1);
    },
  },
  watch: {
    documentType(val) {
      if (val != undefined) {
        if (val == 4) {
          this.ncre();
          this.exempt = "0";
          this.body.isr = "0";
          this.body.iva = "0";
          this.body.discount = "0";
          this.body.amount = "0";
        }
        this.body.document_type_id = val;
      }
    },
    companyId(val) {
      if (typeof val === "object") {
        this.companyId = this.companyId.id;
      }
      this.body.company_id = this.companyId;
      if (this.documentType == 4) {
        this.ncre();
        this.invoices = [];
        this.excludeInvoices = [];
        this.invoice = {};
      }
    },
    classificationId: function (val) {
      if (val != undefined && val.id > 0) {
        this.body.classification_id = val.id;
      }
    },
    selected: function () {
      let total = 0;
      this.selected.forEach((el, key, array) => {
        if (key === array.length - 1) {
          this.body.description = el.description;
        }
        this.body.total_order = 0;
        total = parseFloat(total, 2) + parseFloat(el.amount, 2);
      });
      this.body.total_order = Number(total).toFixed(2);
    },
    businessId: function (val) {
      if (typeof val === "object") {
        this.body.company_id = val.id;
      } else {
        this.body.company_id = val;
      }
    },
    providerId: function (val) {
      if (typeof val === "object") {
        this.body.provider_id = val.id;
      } else {
        this.body.provider_id = val;
      }
      if (
        this.body.invoice_type == "ORDCRE" ||
        this.body.invoice_type == "INVOICE" ||
        this.body.invoice_type == "VOUCHER"
      ) {
        this.getOrders();
      }
      if (this.documentType == 4) {
        this.ncre();
        this.invoices = [];
        this.excludeInvoices = [];
        this.invoice = {};
      }
    },
    provider: function (val) {
      this.providerId = val;
      this.selected = [];
      this.getOrders();
    },
    business: function (val) {
      this.businessId = val;
      this.getOrders();
    },
    type: function (val) {
      this.body.invoice_type = val;
      this.getOrders();
    },
    credit: function (val) {
      this.type = val;
    },
    thereIsExempt: function (val) {
      if (val) {
        if (this.thereIsExempt) {
          this.exemptRule = [rules.required, rules.minValue(0)];
        } else {
          this.exemptRule = [];
        }
      } else {
        this.body.type_exempt_id = undefined;
        this.body.exempt = "0";
      }
    },
    body: {
      handler: function () {
        if (
          this.body.isr != undefined &&
          this.body.isr.length > 0 &&
          this.body.isr >= 0
        ) {
          this.isrRule = [];
        } else {
          this.isrRule = [rules.required, rules.minValue(0)];
        }
        if (this.body.iva != undefined && this.body.iva >= 0 && this.body.iva) {
          this.ivaRule = [];
        } else {
          this.ivaRule = [rules.required, rules.minValue(0)];
        }
        if (
          this.body.discount != undefined &&
          this.body.discount >= 0 &&
          this.body.discount > 0
        ) {
          this.discRule = [];
        } else {
          this.discRule = [rules.required, rules.minValue(0)];
        }
      },
      deep: true,
    },
    invoice(val) {
      if (val != undefined) {
        let exist = [];
        if (JSON.stringify(val) != "{}") {
          exist = this.invoices.filter((element) => element.id == val.id);
        }
        if (exist.length == 0 && JSON.stringify(val) != "{}") {
          this.invoices.push({
            id: val.id,
            description: val.description,
            code: val.serie + "-" + val.invoice_number,
            balance: val.balance,
            amount: val.amount,
            payment: 0,
          });

          this.excludeInvoices.push(val.id);
        } else if (exist.length > 0 && JSON.stringify(val) != "{}") {
          this.$toasted.global.info({
            message: "Esta factura ya ha sido agregada",
          });
        }
        this.resetVal();
      }
    },
    invoices: {
      handler() {
        let total = 0;
        if (this.invoices.length > 0) {
          this.invoices.forEach((el) => {
            if (Number(el.balance) >= Number(el.payment)) {
              total += Number(el.payment);
            } else {
              this.$toasted.global.info({
                message: "Descuento no debe superar el saldo del documento",
              });
              el.payment = 0;
            }
          });
        }
        this.body.amount = Number(total).toFixed(2);
      },
      deep: true,
    },
  },
  mounted() {
    this.businessId = this.business;
    this.providerId = this.provider;
    this.type = this.credit;
    this.getTributaryDocumentType();
    this.getTypeExempt();
    this.getWorkingPeriod();
  },
};
</script>
